<template>
    <div class="test-timer">
        <div class="test-end__title">
            <div class="test-end__pic">
                <img class="test-end__img" src="~assets/img/favicons/good-icon.svg" alt="" width="55" height="55">
            </div>
            <span>Вы прошли тестирование!</span>
        </div>
        <div class="test-end__count-block">
            <div class="test-end__section ml-0">
                <span class="test-end__count">{{ test.answer_correct }}</span>
                <span class="test-end__lore">
                    {{ functions.num2str(test.answer_correct, phrases.correct) }}
                    {{ functions.num2str(test.answer_correct, phrases.answer) }}
                    из
                    {{test.questions_count}}
                </span>
            </div>

        </div>
        <router-link v-if="false" tag="a" to="/" class="test__link-to-main">
            Вернуться на главную
        </router-link>
    </div>
</template>

<script>
    import session from '@/api/session';
    import functions from "assets/js/functions";

    export default {
        name: "End",
        data() {
            return {
                functions,
                phrases: {
                    correct: ['правильный', 'правильных', 'правильных'],
                    answer: ['ответ', 'ответа', 'ответов'],
                },
                first: 6,
                second: 2,
                test: {
                    is_passed: false,
                    is_wait: false,
                    material_list: [],
                    questions_count: 0,
                    answer_wait: 0,
                    answer_correct: 0,
                }
            }
        },
        methods: {
            async loadData() {
                const request = await session.get('/api/v1/testing/end/');
                this.test = request.data;
            }
        },
        async created() {
            await this.loadData();
        }
    }
</script>

<style lang="scss" scoped>
@import "stages";
</style>
